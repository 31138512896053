import React, {useRef, useState, useEffect} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useUserContext } from "../../contexts";
import useWindowSize from "../../hooks/useWindowSize";
import { getProducts, Analytics } from '../../lib/api';
import { Header } from '../Header';
import Cta from './Cta';
import { Faq } from '../Faq';
import { Spinner } from '../Spinner';
import { Footer } from '../Footer';
import productsMetadata from '../../constants/plans';

import './Plans.v2.scss';

const PlansV2 = ({donator=true }) => {

  //Custom Hooks
  const plansRef = useRef(null);
  const {isAuthenticating, user, setUserToken} = useUserContext();
  let { campaign, msg } = useParams();

  const location = useLocation();

  const query = new URLSearchParams(location.search);

  //State to control the loading spinner
  const [showLoading, setShowLoading] = useState(false);
 
  //State to control the payment provider
  const [provider, setProvider] = useState(query.get('prov') && query.get('prov') === 'p'?'paypal':'stripe');

  //State to control/filter what plans to show Month/Year (for the moment)
  const [planPeriod, setPlanPeriod] = useState(query.get('freq') && query.get('freq') === 'y'?'Year':'Month');

  //Used to control the donator/free toggle switch
  const [donatorCheck, setDonatorCheck] = useState(donator);

  //This is the list of products that will be shown in the plans section
  const [products, setProducts] = useState([]);

  const [userCurrentPrice, setUserCurrentPrice] = useState(0);

  const [accentPlanBoxWidth, setAccentPlanBoxWidth] = useState(0);

  const [showPeriodSwitch, setShowPeriodSwitch] = useState(false);

  const windowSize = useWindowSize();

  const searchPlan = plan_id => {
    let plan = null;
    console.log(products);
    Object.keys(products).map(k => products[k]).forEach(prod => {
        console.log(+prod.id,+plan_id)
        if(+prod.monthly?.id === +plan_id){
            plan = prod.monthly;
            return;
        }
        if(+prod.annual?.id === +plan_id){
            plan = prod.annual;
            return;
        }
        if(+prod.free?.id === +plan_id){
            plan = prod.free;
            return;
        }
    });
    console.log("found plan", plan);
    return plan;
};
  
  const filterProducts = (data) => {

    let newProducts = {};

    data.forEach((prod, i) => {
        console.log("PROD", prod.name.toLowerCase());

        const monthlyPaidPlan = prod.plans.filter(p => p.periodicity_type.toLowerCase() === 'month' && p.name.toLowerCase() !== 'free').shift();
        const annualPaidPlan = prod.plans.filter(p => p.periodicity_type.toLowerCase() === 'year' && p.name.toLowerCase() !== 'free').pop();

        const metadata =  annualPaidPlan?.metadata ||  monthlyPaidPlan?.metadata || productsMetadata.find(pm => pm.name.toLowerCase() === prod.name.toLowerCase());

        newProducts[prod.name.toLowerCase()] = {
            ...prod,
            metadata: metadata,
            annual: annualPaidPlan??null,
            monthly: monthlyPaidPlan??null,
            free: prod.plans.filter(p => p.name.toLowerCase() === 'free').pop(),
        };
    });
    
    return newProducts;
  };

  //USeEffects listeners
  useEffect(() => {
    //getProducts({campaign}).then(data => console.log("By API DATA", data));
    //let campaign = null;

    getProducts({campaign}).then(data => {
     const filteredProducts = filterProducts(data);
     console.log({campaign, filteredProducts});
     setProducts(filteredProducts);
     console.log({filteredProducts});
     let hasAnnual = 0, hasMonthly = 0;

     Object.keys(filteredProducts).map(k => filteredProducts[k]).forEach(pr => {
        if(pr.annual){
            hasAnnual++;
        }
        if(pr.monthly){
            hasMonthly++;
        }
     })
     if (hasAnnual === 0 && hasMonthly>0) {
        setShowPeriodSwitch(false);
        setPlanPeriod('Month');
     }
     if (hasAnnual > 0 && hasMonthly === 0) {
        setShowPeriodSwitch(false);
        setPlanPeriod('Year');
     }
     if(hasAnnual>0 && hasMonthly>0){
        setShowPeriodSwitch(true);
     }
    })
    .catch(e => {
        console.log("Problem while getting products", e, {campaign});
    });
  }, []);
  useEffect(() => {
    if(isAuthenticating){
        return;
    }
    if (user && user.team && user.team.subscription.plan) {
        setUserCurrentPrice(user.team.subscription.plan.price);
    }
   }, [isAuthenticating, user]);

  useEffect(() => {
    if(isAuthenticating){
        return;
    }
    
    if (Object.keys(products).length>0) {
        console.log("PRODUCTS TO SHOW", products);
        setShowLoading(false);
    }
  }, [products, isAuthenticating, user]);

  useEffect(() => {
    if (planPeriod === 'Year') {
        const accentPlanBox = document.getElementById('accent-plan-box');
        if(accentPlanBox){
            setAccentPlanBoxWidth(accentPlanBox.offsetWidth);
            setTimeout(() => setAccentPlanBoxWidth(accentPlanBox.offsetWidth), 400);
        }
        return;
    }
    setAccentPlanBoxWidth(0);
    setTimeout(() => setAccentPlanBoxWidth(0), 400);
  }, [planPeriod, windowSize]);

  const handleCta = plan_id =>{
    console.log("selected plan", plan_id);
    //setShowLoading(true);
    try{
        const plan = searchPlan(plan_id);
        console.log("plan", plan);
        Analytics.addToCart(plan.price);
    }catch(e){
        console.log("analytics error", e);
    }
    setTimeout(() => document.location.href=`/signup/${plan_id}/${provider==='stripe'?'s':'p'}${campaign?'?c='+campaign:''}`, 300);
  };

  const [showFeatures, setShowFeatures] = useState([]);

  const toggleFeatures = (index) => {
    let newFeatures = [...showFeatures];
    if(newFeatures.includes(index)){
        newFeatures = newFeatures.filter(f => f !== index);
    }else{
        newFeatures.push(index);
    }
    setShowFeatures(newFeatures);
  };

  const getTitleFromCampaign = (c) => {
    if (!c){
        return 'Our plans';
    }
    return {
        'black-friday': 'Black Friday Special',
        'jalex': 'Jalex Rosa Discount'
    }[c];
  };
  const canApplyToCampaign = (c) => {
    if (campaign === 'jalex') {
        if(user && user.team && user.team.subscription && user.team.subscription.plan && user.team.subscription.plan.price > 0){
            return false;
        }
        //if (user && user.team && ((user.team.subscription.plan.periodicity_type == 'Month' && user.team.subscription.plan.price > 0) || ['pro', 'enterprise'].includes(user.team.subscription.plan.product.name.toLowerCase()))) {
        //    return false;
        //}
    }
    return true;
  };
  const getSubtitleFromCampaign = (c, u) => {
    if (!c){
        return false;
    }
    /*
    If user is PRO/Enterprise: display a message that says: “You are already a PRO/Enterprise member, explore our libraries and start creating :slightly_smiling_face: ”
    If user is Donator Monthly: “You already have a paid subscription. Consider cancelling it before applying for this promotion”.
    If user is Donator Yearly: let him upgrade to this promotion
    If suer is Free: let him subscribe to this promotion
    */
    if (!canApplyToCampaign(c)) {
        return `It seems that you already have a paid subscription!<br /><br /> 
        <a style="text-decoration: underline;" href="https://vfx.productioncrate.com/">Explore our libraries</a> and start creating 😊 <br /><br /> 
        Questions? Contact <a style="text-decoration: underline;" href="mailto:support@productioncrate.com">Support@ProductionCrate.com</a>
        `;
    }
    return {
        'jalex': `Limited 50% Off discount, exclusive to the first <span style='color: #F36C17;'>200</span> users.`,
        'black-friday': 'Get 16% Off Your Subscription Plan'
    }[c];
  };
  const getOldPriceFromCampaign = (c) => {
    if (!c){
        return false;
    }
    return {
        'jalex': '$299',
        'black-friday': '$299'
    }[c];
  };

  const getCampaignClass = (c) => {
    if (!c){
        return false;
    }
    return {
        'black-friday': 'black-friday'
    }[c];
  };
  const doNotDonate = () => {
    plansRef.current.scrollIntoView();
    setTimeout(() => setDonatorCheck(false), 600);
  };
  return (
    <>
        <Header minimal={true} />
        {!showLoading && !isAuthenticating &&
        <div className={`plans_v2 ${getCampaignClass(campaign)}`} ref={plansRef}>
            {campaign && campaign =='jalex' &&
            <div
                style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: -1,
                margin: 'auto',
                overflow: 'hidden',
                }}
            >
                <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                >
                <source src="https://public.productioncrate.com/videos/jalex_campaign_bg_v1.mp4" type="video/mp4" />
                </video>
            </div>
            }
            {campaign && campaign =='black-friday' &&
            <div
                style={{
                width: '100%',
                height: '100%',
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                zIndex: -1,
                margin: 'auto',
                overflow: 'hidden',
                opacity: 0.4,
                }}
            >
                <video
                autoPlay
                loop
                muted
                playsInline
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                }}
                >
                <source src="https://public.productioncrate.com/videos/black_friday_campaign_bg_v1.mp4" type="video/mp4" />
                </video>
            </div>
            }
            {campaign && 
            <div className="title m-b-16 xs:m-b-16">
                {getTitleFromCampaign(campaign)}
            </div>
            }
            {!campaign && !isAuthenticating && !user && 
            <div className="title m-b-16 xs:m-b-16">
                Our plans
            </div>
            }
            {!campaign && !isAuthenticating && user && 
            <div className="title m-b-16 xs:m-b-16">Upgrade your account</div>
            }
            {getSubtitleFromCampaign(campaign, user) && 
            <div className="subtitle m-b-16 xs:m-b-0 m-t-12" dangerouslySetInnerHTML={{ __html:getSubtitleFromCampaign(campaign, user)}} />
            }
            {showPeriodSwitch && userCurrentPrice === 0 && 
            <div className="switch m-b-24">
                <div className="option" onClick={() => setPlanPeriod('Month')}>Monthly</div>
                <div className={`bar ${planPeriod=='Month'?'left':'right'}`} onClick={() => setPlanPeriod(planPeriod=='Month'?'Year':'Month')}>
                    <div className="circle"></div>
                </div>
                <div className="option"  onClick={() => setPlanPeriod('Year')}>Yearly</div>
            </div>
            }
            {(!campaign || (campaign && canApplyToCampaign(campaign))) &&
            <div className={`list ${Object.keys(products).length>0?'visible':''} ${planPeriod == 'Year'?'summarized':''} ${getCampaignClass(campaign)}`}>
            {(typeof campaign === 'undefined' || !campaign) && products.pro && products.pro.annual && products.pro.monthly && (userCurrentPrice < products.pro.annual.price) && !(user && user.team.subscription.plan.periodicity_type === 'Month') &&
                <div className={`plan ${products.pro.metadata.accent?'accent':''} super_accent`} id="accent-plan-box" style={{zIndex:'3', marginRight: `${planPeriod == 'Year'?`calc(-${accentPlanBoxWidth}px - 1.5rem`:''}`}} >
                    <div className="subNote">
                        <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                        <path d="M6.19055 10.7957L3.28144 12.6306C3.15292 12.7163 3.01857 12.753 2.87837 12.7407C2.73817 12.7285 2.6155 12.6796 2.51035 12.5939C2.4052 12.5083 2.32342 12.4012 2.265 12.2725C2.20659 12.1438 2.1949 12.0002 2.22995 11.8416L3.00104 8.37359L0.424899 6.04321C0.308067 5.93311 0.235164 5.8076 0.20619 5.66668C0.177216 5.52576 0.185861 5.38826 0.232126 5.25419C0.278859 5.11963 0.348958 5.00953 0.442424 4.9239C0.535889 4.83827 0.664405 4.78322 0.827969 4.75875L4.22778 4.44681L5.54214 1.18062C5.60055 1.03383 5.69121 0.923728 5.81412 0.850331C5.93703 0.776933 6.06251 0.740234 6.19055 0.740234C6.31907 0.740234 6.44455 0.776933 6.56699 0.850331C6.68943 0.923728 6.78009 1.03383 6.83897 1.18062L8.15333 4.44681L11.5531 4.75875C11.7167 4.78322 11.8452 4.83827 11.9387 4.9239C12.0321 5.00953 12.1022 5.11963 12.149 5.25419C12.1957 5.38875 12.2046 5.52649 12.1756 5.66742C12.1466 5.80834 12.0735 5.9336 11.9562 6.04321L9.38007 8.37359L10.1512 11.8416C10.1862 12.0007 10.1745 12.1445 10.1161 12.2732C10.0577 12.4019 9.97591 12.5088 9.87076 12.5939C9.76561 12.6796 9.64294 12.7285 9.50274 12.7407C9.36254 12.753 9.22818 12.7163 9.09967 12.6306L6.19055 10.7957Z" fill="#F36C17"/>
                        </svg>
                        Best Value
                    </div>
                    <div className="name m-b-6 xs:m-b-0">Annual PRO</div>
                    {planPeriod==='Year' &&
                        <div className="old-price">${products.pro.monthly.price}/mo</div>
                    }
                    <div className="price">
                        $
                        <div className="accent">
                            {Math.ceil(products.pro.annual.price/12)}
                        </div>
                        /mo
                    </div>
                    <div className="note -m-t-16 xs:-m-t-8">billed ${products.pro.annual.price} annually</div>
                    <Cta 
                        type="super_accent"
                        label={user?'Upgrade now':'Get Started'}
                        onClick={() => handleCta(products.pro.annual.id)}
                        disabled={user && user.team && user.team.subscription.plan.id === products.pro.annual.id}
                    />
                    <div className={`features sm:m-b-12 ${showFeatures.includes(3)?'visible':'collapsed'} `}>
                        {products.pro.metadata.features && products.pro.metadata.features.map((feature, feature_index) => (
                        <div className="item" key={`product-feature-${feature_index}`}>
                            {feature.variable && 
                                <div style={{display: 'inline-flex', gap: '4px'}}>
                                    {feature.donatorValue}
                                    {feature.name}
                                </div>
                            }
                            {!feature.variable && 
                                <>
                                {feature.link && 
                                <a href={feature.link} target="_blank">{feature.name}</a>   
                                }
                                {!feature.link &&
                                    <>
                                        {feature.name}
                                    </>
                                }
                                </>
                            }
                            {feature.tooltip && 
                                <div className="tooltip">
                                    {feature.tooltip}
                                </div>
                            }
                        </div> 
                        ))}
                    </div>
                    <div className={`show-features ${showFeatures.includes(3)?`collapsed`:`visible`}`} onClick={() => toggleFeatures(3)}>
                        {!showFeatures.includes(3)?`What's included?`:`Hide details`}
                        <svg className="icon" style={{filter: 'invert(0)'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </div>
                </div>
            }
            {Object.keys(products).map(k => products[k]).filter(p => p[planPeriod=='Year'?'annual':'monthly'] && userCurrentPrice < p[planPeriod=='Year'?'annual':'monthly'].price).map((product, product_index) => (
                <div className={`plan ${getCampaignClass(campaign)} ${product.metadata && product.metadata.accent?'accent':''} ${product.free?'with_toggle':''} ${!campaign && planPeriod == 'Year' && product.metadata.name.toLowerCase() === 'pro'?'hidden':''}`} key={product_index} style={{order: `${product.metadata.order}`}}
                >
                    <div className='bg_whiter'></div>
                    <div className="name m-b-6 xs:m-b-0">
                        {product.metadata.name}
                    </div>
                    {campaign && product.annual && 
                    <>
                        <div className="old-price">{getOldPriceFromCampaign(campaign)}</div>
                        <div className="price">
                            <div className="accent">${product.annual.price}</div>/yr
                        </div>
                        <div className="note -m-t-16 xs:-m-t-8">discounted price</div>
                    </>
                    } 
                    {campaign && !product.annual && product.monthly &&
                    <>
                        <div className="old-price">{getOldPriceFromCampaign(campaign)}</div>
                        <div className="price">
                            <div className="accent">${product.monthly.price}</div>/mo
                        </div>
                        <div className="note -m-t-16 xs:-m-t-8">discounted price</div>
                    </>
                    } 
                    {!campaign && 
                    <>
                        {planPeriod==='Year' && (!product.free || product.free && donatorCheck) && product.monthly &&
                        <div className="old-price" style={product.metadata.name.toLowerCase() === 'enterprise'?{opacity: '0'}:{}}>${product.monthly.price}/mo</div>
                        }
                        {!campaign && planPeriod==='Year' && product.free && !donatorCheck &&
                        <div className="old-price">-</div>
                        }
                        {!campaign && 
                        <div className="price">
                            {product.metadata.name.toLowerCase() !== 'enterprise' &&
                            <>
                            $
                            <div className="accent">
                                {(!product.free || (product.free && donatorCheck))?
                                `${planPeriod==='Year'?Math.ceil(product.annual.price/12):product.monthly.price}`
                                :
                                '0'
                                }
                            </div>
                            {planPeriod == 'Year'?'/mo':''}
                            </>
                            }
                            {product.metadata.name.toLowerCase() === 'enterprise' &&
                            <img className="icon" style={{width: '4.6rem',marginTop: '0.625rem'}} src="/images/icon.enterprise.svg" alt="enterprise icon" />
                            }
                        </div>
                        } 
                        {planPeriod==='Year' && (!product.free || (product.free && donatorCheck)) && product.metadata.name.toLowerCase() !== 'enterprise' &&
                        <div className="note -m-t-16 xs:-m-t-8">billed ${product.annual.price} annually</div>
                        }
                        {planPeriod==='Month' && (!product.free || (product.free && donatorCheck)) && product.metadata.name.toLowerCase() !== 'enterprise' &&
                        <div className="note -m-t-16 xs:-m-t-8">per month</div>
                        }
                        {!donatorCheck && product.free && 
                        <div className="note -m-t-16 xs:-m-t-8">optional billing</div>
                        }
                    </>
                    }

                    {!donatorCheck && product.free && 
                    <Cta
                        label={user?'Current':product.metadata.cta}
                        type={product.metadata.accent?'accent':''}
                        onClick={() => handleCta(product.free.id)}
                        disabled={user && user.team.subscription.plan.id === product[planPeriod=='Year'?'annual':'monthly'].id}
                    />
                    }  
                    {(donatorCheck || !product.free) &&  
                    <Cta
                        label={user?(user.team.subscription.plan.id === product[planPeriod=='Year'?'annual':'monthly'].id?'Current':'Upgrade now'):product.metadata.cta}
                        type={product.metadata.accent?'accent':''}
                        onClick={() => (product.metadata.name.toLowerCase() !== 'enterprise'?handleCta(product[planPeriod=='Year'?'annual':'monthly'].id):document.location.href='/enterprise')}
                        disabled={user && user.team.subscription.plan.id === product[planPeriod=='Year'?'annual':'monthly'].id}
                    />
                    }
                    <div className={`features sm:m-b-12 ${showFeatures.includes(product_index)?'visible':'collapsed'} `}>
                        {product.metadata.features && product.metadata.features.map((feature, feature_index) => (
                        <div className="item" key={`product-${product_index}-feature-${feature_index}`}>
                            {feature.variable && 
                                <div style={{display: 'inline-flex', gap: '4px'}}>
                                    {donatorCheck && <span>{feature.donatorValue} </span>}
                                    {!donatorCheck && <span>{feature.freeValue} </span>}
                                    <span>{feature.name}</span>
                                </div>
                            }
                            {!feature.variable && 
                                <>
                                {feature.link && 
                                <a href={feature.link} target="_blank">{feature.name}</a>   
                                }
                                {!feature.link &&
                                    <>
                                        {product.plans[0]?.id === 49?
                                            feature.name.replace('50', '100')
                                            :
                                            feature.name
                                        }
                                    </>
                                }
                                </>
                            }
                            {feature.tooltip && 
                                <div className="tooltip">
                                    {product.plans[0]?.id === 49?
                                        feature.tooltip.replace('50', '100')
                                        :
                                        feature.tooltip
                                    }
                                </div>
                            }
                        </div> 
                        ))}
                    </div>
                    <div className={`show-features ${showFeatures.includes(product_index)?`collapsed`:`visible`}`} onClick={() => toggleFeatures(product_index)}>
                        {!showFeatures.includes(product_index)?`What's included?`:`Hide details`}
                        <svg className="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg>
                    </div>
                    {product.free && 
                    <label className="donate" htmlFor="donate-checkbox">
                        <input type="checkbox" id="donate-checkbox" value={donatorCheck} onChange={() => setDonatorCheck(!donatorCheck)} checked={donatorCheck} />
                        Donate $15/mo for 4x Downloads
                    </label>
                    }
                </div>
            ))}
            </div>
            }
            {(!campaign || (campaign && canApplyToCampaign(campaign))) &&
            <>
                <div className={`switch m-b-40 m-t-48 xs:m-t-32 ${getCampaignClass(campaign)}`} id="plan-provider-switch">
                    <div className="option" onClick={() => setProvider('paypal')}>Paypal</div>
                    <div className={`bar ${provider=='paypal'?'left':'right'}`} onClick={() => setProvider(provider=='paypal'?'stripe':'paypal')}>
                        <div className="circle"></div>
                    </div>
                    <div className="option"  onClick={() => setProvider('stripe')}>Stripe</div>
                </div>
                <div className="custom-claim">
                    <b>Looking for a custom solution?</b> 
                    <a href="mailto:support@productioncrate.com">Contact us</a> 
                    and we'll build a custom plan for you, tailored to your specific business needs.
                </div>
            </>
            }
            
        </div>
}
        {(showLoading || isAuthenticating) && 
            <div style={{display: 'flex', alignItems:'center', justifyContent: 'center', width: '100%', height: 'calc(80vh - 300px)'}}>
                <Spinner style={{position: 'relative'}} />
            </div>
        }
        <Faq donateUncheckHandle={doNotDonate} />
        <Footer />
    </>
  );
};

export default PlansV2;
